<template>
  <div class="item">
    <v-btn 
      class="c-btn" 
      small
      @click="dialog(1)"
    ><v-icon left small>mdi-school</v-icon>План теоретических занятий</v-btn>
    <v-btn 
      class="c-btn" 
      small
      @click="dialog(2)"
    ><v-icon left small>mdi-steering</v-icon>План практических занятий</v-btn>

        <v-row justify="center">
          <v-dialog v-model="dialog1" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
              <v-toolbar dark color="">
                <v-toolbar-title>План теоретических занятий</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialog1 = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-divider></v-divider>
              <Table1/>
            </v-card>
          </v-dialog>
        </v-row>

        <v-row justify="center">
          <v-dialog v-model="dialog2" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
              <v-toolbar dark color="">
                <v-toolbar-title>План практических занятий</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialog2 = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-divider></v-divider>
               <Table2/>
            </v-card>
          </v-dialog>
        </v-row>

  </div>
</template>

<script>
import Table1 from '@/components/Table1'
import Table2 from '@/components/Table2'
export default {
  components: {Table1, Table2},
  data: () => ({
    dialog1: false,
    dialog2: false,
  }),
  methods: {
    dialog(num) {
      num == 1 ? this.dialog1 = true : false
      num == 2 ? this.dialog2 = true : false
    }
  },
}
</script>

<style lang="scss" scoped>
  .step-alert {
    cursor: pointer;
  }
  .v-card > *:first-child:not(.v-btn):not(.v-chip), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .c-btn {
    margin: 5px;
    min-width: 245px;
  }
</style>