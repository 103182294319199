<template>
  <div>
    <div class="app-container courses">
      <div class="courses-item" id="course1">
        <v-card class="card gr" :elevation="elevation">
          <div>
            <h3>Курсы международников:</h3>
            <v-timeline dense>
              <v-timeline-item color="green" small>Заполнение документации: CMR, TIR, T1, ДКД</v-timeline-item>
              <v-timeline-item color="green" small>Работа с тахографом</v-timeline-item>
              <v-timeline-item color="green" small>Погрузка, выгрузка грузов</v-timeline-item>
              <v-timeline-item color="green" small>Крепление и транспортировка грузов</v-timeline-item>
              <v-timeline-item color="green" small>По окончанию выдается удостоверение нового образца</v-timeline-item>
            </v-timeline>
            <div class="card-img">
              <div v-for="(img, i) in cardImg" :key="'ci' + i">
                <v-img max-width="210" :src="img" @click="show(i)"></v-img>
              </div>
            </div>
          </div>
          <div>
            <table class="table-item">
              <tr>
                <td>Требования:</td>
                <td>{{getCourses.truck.treb}}</td>
              </tr>
              <tr>
                <td>Документы:</td>
                <td>{{getCourses.truck.doc}}</td>
              </tr>
              <tr>
                <td>Срок обучения:</td>
                <td>{{getCourses.truck.term}}</td>
              </tr>
              <tr>
                <td>Стажировака проходит на автомобилях:</td>
                <td>{{getCourses.truck.car}}</td>
              </tr>
              <tr>
                <td>Выдаваемые документы:</td>
                <td>{{getCourses.truck.doc_}}</td>
              </tr>
              <tr>
                <td>Стоимость:</td>
                <td>{{getCourses.truck.cost}}</td>
              </tr>
            </table>
          </div>
        </v-card>
      </div>
      <div class="courses-item" id="course2">
        <v-card class="card gr" :elevation="elevation">
          <div>
            <h3>Курсы дальнобойщиков:</h3>
            <v-timeline dense>
              <v-timeline-item color="green" small>Занятия начинаются в понедельник</v-timeline-item>
              <v-timeline-item color="green" small>Проходят каждый день</v-timeline-item>
              <v-timeline-item color="green" small>Иногородним возможно предостовление жилья</v-timeline-item>
            </v-timeline>
            <br>
            <CoursesStep/>
            <!-- <v-btn class="c-btn" small><v-icon left small>mdi-school</v-icon>План теоретических занятий</v-btn>
            <v-btn class="c-btn" small><v-icon left small>mdi-steering</v-icon>План практических занятий</v-btn> -->
          </div>
          <div>
            <table class="table-item">
              <tr>
                <td>Стажировака проходит на автомобилях:</td>
                <td>{{getCourses.truckD.car}}</td>
              </tr>
              <tr>
                <td>Срок обучения:</td>
                <td>{{getCourses.truckD.term}}</td>
              </tr>
              <tr>
                <td>Стоимость:</td>
                <td>{{getCourses.truckD.cost}}</td>
              </tr>
            </table>
          </div>
        </v-card>
      </div>
      <div class="courses-item" id="course3">
        <v-card class="card" :elevation="elevation">
          <h3>Разовые занятия на тягаче:</h3>
          <div class="gr">
            <div>
              <table class="table-item">
                <tr>
                  <td>Занятие на тягаче:</td>
                  <td>автодром</td>
                </tr>
                <tr>
                  <td>Автомобили:</td>
                  <td>{{getCourses.truckR.autodrome.car}}</td>
                </tr>
                <tr>
                  <td>Стоимость одного занятия:</td>
                  <td>{{getCourses.truckR.autodrome.cost}}</td>
                </tr>
              </table>
            </div>
            <div>
              <table class="table-item">
                <tr>
                  <td>Занятие на тягаче:</td>
                  <td>городской цикл</td>
                </tr>
                <tr>
                  <td>Автомобили:</td>
                  <td>{{getCourses.truckR.city.car}}</td>
                </tr>
                <tr>
                  <td>Стоимость одного занятия:</td>
                  <td>{{getCourses.truckR.city.cost}}</td>
                </tr>
              </table>
            </div>
          </div>
        </v-card>
      </div>
      <div class="courses-item" id="course4">
        <v-card class="card gr" :elevation="elevation">
            <div>
              <h3>ADR. Перевозка опасных грузов:</h3>
              <v-timeline dense>
                <v-timeline-item color="green" small>Водителям, осваивающим образовательную программу, выдаются: ПИСЬМЕННЫЕ ИНСТРУКЦИИ В СООТВЕТСТВИИ С ДОПОГ и учебно-практическое пособие "ПЕРЕВОЗКА ОПАСНЫХ ГРУЗОВ В МЕЖДУНАРОДНОМ СООБЩЕНИИ"</v-timeline-item>
                <v-timeline-item color="green" small>Успешное освоение базовой программы позволит водителям перевозить опасные грузы классов 2, 3, 4.1, 4.2, 4.3, 5.1, 5.2, 6.1, 6.2, 8, 9 в упаковках, контейнерах, баллонах и т.д.</v-timeline-item>
                <v-timeline-item color="green" small>Успешное освоение курса перевозки опасных грузов в цистернах позволит водителям перевозить опасные грузы классов 2, 3, 4.1, 4.2, 4.3, 5.1, 5.2, 6.1, 6.2, 8, 9 в цистернах.</v-timeline-item>
                <v-timeline-item color="green" small>По окончании курсов выдается справка об обучении</v-timeline-item>
                <v-timeline-item color="green" small>Для получения Свидетельства ДОПОГ (ADR) после обучения слушатели должны сдать экзамен в территориальной комиссии Госпромнадзора.</v-timeline-item>
              </v-timeline>
            </div>
            <div>
              <table class="table-item">
                <tr>
                  <td colspan="2">Базовая программа:</td>
                </tr>
                <tr>
                  <td>Срок обучения:</td>
                  <td>{{getCourses.adr.base.term}}</td>
                </tr>
                <tr>
                  <td>Стоимость:</td>
                  <td>{{getCourses.adr.base.cost}}</td>
                </tr>
                <tr>
                  <td colspan="2">Перевозка опасных грузов в цистернах:</td>
                </tr>
                <tr>
                  <td>Срок обучения:</td>
                  <td>{{getCourses.adr.tank.term}}</td>
                </tr>
                <tr>
                  <td>Стоимость:</td>
                  <td>{{getCourses.adr.tank.cost}}</td>
                </tr>
              </table>
            </div>
        </v-card>
      </div>
      <div class="courses-item" id="course5">
        <v-card class="card doc-wrap" :elevation="elevation">
          <h3>Методические материалы: <span v-if="getDoc.length == 0"><strong style="color: green">отсутствуют:(</strong></span></h3>
            <table class="table-doc">
              <tr v-for="(item, i) in getDoc" :key="`doc${i}`">
                <td>
                  <div class="doc" @click="openDialog(item)" style="cursor: pointer">
                    {{`${item.name} (${item.filename.split('.')[item.filename.split('.').length - 1]})`}}
                  </div>
                </td>
              </tr>
            </table>
        </v-card>
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <div class="dialog-pass">
          <v-form ref="form">
            <v-text-field
              :label="lable"
              outlined
              color="#4caf50"
              :rules="docPassRules"
              v-model="docPass"
            ></v-text-field>
          </v-form>
          <div class="btn-pass">
            <v-btn small @click="dialogCancel">отмена</v-btn>
            <v-btn small @click="openFile()">далее</v-btn>
          </div>
        </div>
    </v-card>
    </v-dialog>
    <div class="courses-menu">
      <div class="cmi-wrap">
        <div v-for="(item, i) in cmi" :key="`cmi${i}`">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <div class="cm-item" v-bind="attrs" v-on="on" @click="nav(item.id)">
                <div class="cmi-i"></div>
              </div>
            </template>
            <span>{{item.desc}}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="cardImg"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import CoursesStep from '@/components/CoursesStep.vue'
import {mapGetters} from 'vuex'
export default {
  components: { CoursesStep },
  props: {
    loadingWindow: Function
  },
  data: () => ({
    visible:false,
    index: null,
    cardImg: ['img/card/card-1.jpg','img/card/card-2.jpg'],
    index: null,
    elevation: 10,
    lable: 'Введите пароль',
    err: 'Пароль не должен быть пустым',
    dialog: false,
    docPass: null,
    docPassRules: [
      v => !!v || 'Пароль не должен быть пустым'
    ],
    doc: {},
    cmi: [
      {id: 'course1', desc: 'Курсы международников'},
      {id: 'course2', desc: 'Курсы дальнобойщиков'},
      {id: 'course3', desc: 'Разовые занятия на тягаче'},
      {id: 'course4', desc: 'ADR. Перевозка опасных грузов'},
      {id: 'course5', desc: 'Методические материалы'},
    ]
  }),
  computed: {
    ...mapGetters(['getDoc', 'getCourses'])
  },
  methods: {
    handleHide() {
      this.visible = false
    },
    show(i) {
      this.index = i
      this.visible = true
    },
    nav(id) {
      let item = document.querySelector(`#${id}`)
      item.scrollIntoView({block: 'center', behavior: 'smooth'})
    },
    openDialog(item) {
      this.dialog = true
      this.doc.id = item.id
      this.doc.filename = item.filename
      this.doc.name = item.name
    },
    async openFile() {
      this.lable = 'Введите пароль'
      if (this.$refs.form.validate()) {
        this.loadingWindow(true)
        let response = await this.$store.dispatch('checkDocPass', {pass: this.docPass.trim(), fileID: this.doc.id})
        this.loadingWindow(false)
        if (response.data.type == 'application/json') {
          const pass = await response.data.text()
          if (JSON.parse(pass).pass == false) {
            this.lable = '[ошибка:] Неверный пароль!'
            this.$refs.form.reset()
            this.docPass = null
          }
        } else {
          let blob = new Blob([response.data], { type: `${response.headers["content-type"]}` } )
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${this.doc.name}`
          link.click()
          URL.revokeObjectURL(link.href)
          this.dialogCancel()
        }
      }
    },
    dialogCancel() {
      this.$refs.form.reset()
      this.lable = 'Введите пароль'
      this.docPass = null
      this.dialog = false
      this.doc = {}
    }
  },
  mounted() {
    window.scrollTo({top: 0})
  }
}
</script>

<style lang="scss" scoped>
  .gr {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 10px;
  }
  @media only screen and (max-width: 975px) {
    .gr {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .gr2 {
    display: grid;
  }
  .card {
    padding: 15px;
    .table-item {
      width: 100%;
      td {
        border: 1px solid #d3d3d3;
        border-radius: 3px;
        padding: 3px;
      }
    }
  }
  .courses {
    margin-top: 100px;
    .courses-item {
      margin-bottom: 30px;
    }
  }
  .dialog-pass {
    padding: 15px;
    .btn-pass {
      text-align: center;
      button {
        margin: 0 4px;
      }
    }
  }
  .doc-wrap {
    .table-doc {
      width: 100%;
      td {
        padding: 7px 0;
        border-bottom: 1px solid #d3d3d3;
      }
    }
    .doc {
      font-weight: bold;
      transition: all .3s;
      display: inline-block;
      color: #006400;
      &:hover {
        color: #FF4500;
        transform: translateX(7px)
      }
    }
  }
  .courses-menu {
    position: fixed;
    z-index: 7;
    width: 30px;
    height: 250px;
    background-color: rgba(0, 0, 0, 0.7);
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0 5px 5px 0;
    display: grid;
    align-content: center;
    justify-content: center;
    .cmi-wrap {
      padding: 0 10px 0 6px;
      .cm-item {
        padding: 9px;
        border-radius: 50%;
        /* border: 2px solid #00FF00; */
        border: 2px solid #C0C0C0;
        margin: 17px 0;
        cursor: pointer;
        position: relative;
        transition: all .3s;
        &:hover {
          border: 2px solid #00FF00;
          .cmi-i {
            background-color: #00FF00;
          }
        }
        .cmi-i {
          transition: all .3s;
          padding: 6px;
          /* background-color: #00FF00; */
          background-color: #C0C0C0;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .card-img {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    .v-image {
      margin-top: 9px;
      border-radius: 5px;
      &:nth-child(1) {
        margin-right: 7px;
      }
    }
  }
  @media only screen and (max-width: 1300px) {
    .courses {
      margin-left: 35px;
    }
  }
</style>
